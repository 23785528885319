import AppHeader from "../components/ChildrenPage/appHeader";
import {useTranslation} from "react-i18next";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React, {useEffect, useState} from "react";
import AppFooter from "../components/appFooter";
import FsLightbox from "fslightbox-react";
import ImageSlider from "../components/ChildrenPage/ImageSlider";
import Image from 'react-bootstrap/Image'

const PageForChildren = () => {
    const [openLightBox, setOpenLightBox] = useState(false);
    const {t, i18n} = useTranslation();
    const [graphImage, setGraphImage] = useState("/graf-deti-cz.png");

    useEffect(() => {
        switch (i18n.language) {
            case("ru"):
                setGraphImage("/graf-deti-ru.png");
                break;
            case("ua"):
                setGraphImage("/graf-deti-ua.png");
                break;
            default:
                setGraphImage("/graf-deti-cz.png");
                break;
        }
    }, [t, i18n.language]);

    return (
        <>
            <AppHeader></AppHeader>
            <div className={"App-content"}>
                <Container>
                    <h1>{t("children.LIA_title")}</h1>
                    <Row>
                        <Col xs={12} md={6}>
                            <a className="phone-number-a" href={"tel:" + t("children.LIA_number")}><p
                                className="phone-number">{t("children.LIA_number")}</p></a>
                        </Col>
                        <Col>
                            <p>{t('children.opening_hours')}<br/>
                                <b style={{whiteSpace: "pre-line"}}>{t('provozni_doba_krizove_linky')}</b></p>
                            <b>{t('children.telephone_is_free')}</b>
                        </Col>
                    </Row>
                    <br/>
                </Container>
                <div className="Section Section-image-slider">
                    <Container>
                        <a class="anchor" id="Stories"></a>
                        <h1>{t("children.what_do_we_do")}:</h1>
                    </Container>
                        <ImageSlider></ImageSlider> 
                    <Container>
                    </Container>
                </div>

                <div className="Section Section-type-2">
                    <Container>
                        <br/>
                        <Row>
                            <Col sm={0} md={1}></Col>
                            <Col sm={12} md={10}>
                                <Image fluid={true} src={graphImage} onClick={() => setOpenLightBox(!openLightBox)}></Image>
                                <FsLightbox
                                    toggler={openLightBox}
                                    sources={[graphImage]}
                                />
                            </Col>
                            <Col sm={0} md={1}></Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <AppFooter></AppFooter>
        </>
    )
}

export default PageForChildren;