import React from "react";
import { withTranslation } from "react-i18next";
import ContentSectionWeOffer from "./contentSectionWeOffer";
import ContentSectionWhenYouCall from "./contentSectionWhenYouCall";
import ContentSectionOpeningHours from "./contentSectionOpeningHours";
import ContentSectionOperator from "./contentSectionOperator";
import ContentSectionAnotherHelpOptions from "./contentSectionAnotherHelpOptions";
import ContentSectionStories from "./contentSectionStories";
import ContentSectionPageTitle from "./contentSectionPageTitle";

class AppContent extends React.Component {
  render() {
    return (
      <div className="App-content">
        <ContentSectionPageTitle></ContentSectionPageTitle>
        <ContentSectionWeOffer></ContentSectionWeOffer>
        <ContentSectionWhenYouCall></ContentSectionWhenYouCall>
        <ContentSectionOpeningHours></ContentSectionOpeningHours>
        <ContentSectionOperator></ContentSectionOperator>
        <ContentSectionStories></ContentSectionStories>
        <ContentSectionAnotherHelpOptions></ContentSectionAnotherHelpOptions>
      </div>
    );
  }
}

export default withTranslation()(AppContent);
