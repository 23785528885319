import React from "react";
import { withTranslation } from "react-i18next";
import Container from "react-bootstrap/Container";

class ContentSectionOpeningHours extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <div className="Section Section-type-1">
        <Container>
          <a class="anchor" id="Opening-hours"></a>
          <h1>{t("opening_hours")}</h1>
          <p>{t("provozni_doba_krizove_linky_je")}</p>
          <p>
            <b>{t("provozni_doba_krizove_linky")}</b>
          </p>
          <p>{t("provozni_doba_infolinky_je_a")}</p>
          <p>
            <b>{t("provozni_doba_infolinky")}</b>
          </p>
          <p>{t("mimo_pracovni_dobu_je_v_provozu_system")}</p>
          <p>{t("opening_hours_text_1")}</p>
        </Container>
      </div>
    );
  }
}

export default withTranslation()(ContentSectionOpeningHours);
