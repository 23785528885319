import React from "react";
import { withTranslation } from "react-i18next";
import Container from "react-bootstrap/Container";

class ContentSectionWhenYouCall extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <div className="Section Section-type-2">
        <Container>
          <a class="anchor" id="When-you-call"></a>
          <h1>{t("when_you_call")}</h1>
          <ol>
            <li>{t("when_you_call_text_1")}</li>
            <li>
              {t("when_you_call_text_2")}
              <b>{t("when_you_call_text_2b")}</b>
              {t("when_you_call_text_2c")}
              <b>{t("when_you_call_text_2d")}</b>
              {t("when_you_call_text_2e")}
            </li>
            <li>{t("when_you_call_text_3")}</li>
            <li>{t("when_you_call_text_4")}</li>
          </ol>
          <br></br>
          <p>
            <b>{t("we_offer_text_5a")}</b>
            {t("we_offer_text_5b")}
          </p>
          <p>{t("we_offer_text_5c")}</p>
          <br></br>
          <p>
            <b>{t("krizova_linka")}</b>
            {t("je_urcena_vsem_kterych")}
          </p>
          <p>{t("when_you_call_text_5b")}</p>
          <p>{t("when_you_call_text_5c")}</p>
          <br></br>
          <p>
            {t("when_you_call_text_6_a")}
            <a href={"mailto:" + t("when_you_call_text_6_b")}>
              {t("when_you_call_text_6_b")}
            </a>
            {t("when_you_call_text_6_c")}
          </p>
        </Container>
      </div>
    );
  }
}

export default withTranslation()(ContentSectionWhenYouCall);
