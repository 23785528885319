import React from 'react';
import { withTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {Link} from "react-router-dom";

class ContentSectionPageTitle extends React.Component {

  render() {
    const { t } = this.props;
    return (
      <div className="Section Section-type-2">
        <Container>
          <h1>{t("main_heading")}</h1>
          <Row>
            <Col>
              <b>{t("phonenumber_heading")}</b>
            </Col>
            <Col></Col>
          </Row>
          <Row>
            <Col>
              <a className="phone-number-a" href={"tel:" + t("phonenumber1")}><p className="phone-number">{t("phonenumber1")}</p></a>
            </Col>
            <Col><Link className="phone-number-a" to="/deti"><p className="phone-number">{t("children.link_text")}</p></Link></Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default withTranslation()(ContentSectionPageTitle);