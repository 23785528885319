import React, { useState, useEffect } from "react";
import FsLightbox from "fslightbox-react";
import { useTranslation } from "react-i18next";

const SOURCES_CZ = ["/1cz.png", "/2cz.png", "/3cz.png", "/4cz.png", "/5cz.png", "/6cz.png", "/7cz.png", "/8cz.png"];
const SOURCES_UA = ["/1ua.png", "/2ua.png", "/3ua.png", "/4ua.png", "/5ua.png", "/6ua.png", "/7ua.png", "/8ua.png",];
const SOURCES_RU = ["/1ru.png", "/2ru.png", "/3ru.png", "/4ru.png", "/5ru.png", "/6ru.png", "/7ru.png", "/8ru.png",];

function ImageSlider() {
    const [lightboxController, setLightboxController] = useState({
        toggler: false,
        slide: 1
    });
    const [lightBoxSource, setLightBoxSource] = useState(SOURCES_CZ);
    const { t, i18n } = useTranslation();

    const slickSettings = {
        dots: true,
        infinite: true,
        speed: 1000,
        autoplay: true,
        slidesToShow: 3
    };

    useEffect(() => {
        switch (i18n.language) {
            case ("ru"):
                setLightBoxSource(SOURCES_RU);
                break;
            case ("ua"):
                setLightBoxSource(SOURCES_UA);
                break;
            default:
                setLightBoxSource(SOURCES_CZ);
                break;
        }
    }, [t]);

    function openLightboxOnSlide(number) {
        setLightboxController({
            toggler: !lightboxController.toggler,
            slide: number
        });
    }

    return (
        <>

            <div className="image-slider">
                <img src={lightBoxSource[0]} className="image-card" onClick={() => openLightboxOnSlide(1)}></img>
                <img src={lightBoxSource[1]} className="image-card" onClick={() => openLightboxOnSlide(2)}></img>
                <img src={lightBoxSource[2]} className="image-card" onClick={() => openLightboxOnSlide(3)}></img>
                <img src={lightBoxSource[3]} className="image-card" onClick={() => openLightboxOnSlide(4)}></img>
                <img src={lightBoxSource[4]} className="image-card" onClick={() => openLightboxOnSlide(5)}></img>
                <img src={lightBoxSource[5]} className="image-card" onClick={() => openLightboxOnSlide(6)}></img>
                <img src={lightBoxSource[6]} className="image-card" onClick={() => openLightboxOnSlide(7)}></img>
                <img src={lightBoxSource[7]} className="image-card" onClick={() => openLightboxOnSlide(8)}></img>
            </div>

            <FsLightbox
                toggler={lightboxController.toggler}
                sources={lightBoxSource}
                slide={lightboxController.slide}
            />
        </>
    );
}

export default ImageSlider;