import React from 'react';
import { withTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';

class ContentSectionOperator extends React.Component {
  
  render(){
    const {t} = this.props;
    return (
      <div className="Section Section-type-2">
        <Container>
        <a class="anchor" id="Operator"></a>
        <h1>{t("operator")}</h1>
        <p>{t("operator_text_1")}</p>
        <p>{t("operator_text_2")}</p>
        <p>{t("operator_text_3")}</p>
        </Container>
        </div>
    );
  }
}

export default withTranslation()(ContentSectionOperator);