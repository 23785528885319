import './App.scss';
import React from 'react';
import { withTranslation } from 'react-i18next';
import {Route, Routes} from "react-router-dom";
import HomePage from "./views/HomePage";
import PageForChildren from "./views/PageForChildren";

class App extends React.Component {

  render(){
    
    const { t } = this.props;
    document.title = t("mimoradna_linka");

  
    return (
        <div className="App">
            <Routes>
                <Route path="/" element={ <HomePage/> } />
                <Route path="deti" element={ <PageForChildren/> } />
            </Routes>
        </div>
    );
  }
}

export default withTranslation()(App);
