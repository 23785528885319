import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationCZ from "./assets/locales/cz/translation.json";
import translationUA from "./assets/locales/ua/translation.json";
import translationRU from "./assets/locales/ru/translation.json";

const fallbackLng = ['cz'];
const availableLanguages = ['cz', 'ua', 'ru'];

const resources = {
    cz: {
      translation: translationCZ
    },
    ua: {
      translation: translationUA
    },
    ru: {
      translation: translationRU
    }
  };


i18n
  .use(Backend) // load translations using http (default public/assets/locals/en/translations)
  .use(LanguageDetector) // detect user language
  .use(initReactI18next) // pass the i18n instance to react-i18next.
  .init({
    resources,
    fallbackLng, // fallback language is czech.

    detection: {
      checkWhitelist: true, // options for language detection
    },

    debug: false,

    supportedLngs: availableLanguages,

    interpolation: {
      escapeValue: false, // no need for react. it escapes by default
    },
  });

export default i18n;