import React from "react";
import { withTranslation } from "react-i18next";
import Container from "react-bootstrap/Container";

class contentSectionAnotherHelpOptions extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <div className="Section Section-type-2">
        <Container>
          <a class="anchor" id="Another-help-options"></a>
          <h1>{t("another_help_options")}</h1>
          <ul>
            <li>
              <a href="https://linkasluchatko.cz//">
                {t("another_help_options_text_2")}
              </a>
            </li>
            <li>
              <a href="https://www.opatruj.se//">
                {t("another_help_options_text_3")}
              </a>
            </li>
          </ul>
        </Container>
      </div>
    );
  }
}

export default withTranslation()(contentSectionAnotherHelpOptions);
