import React from "react";
import { withTranslation } from "react-i18next";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import logo from "../../logo.svg";
import LanguageSelector from "../languageSelector";

class AppHeader extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <Navbar expand="lg" sticky="top">
        <Navbar.Brand href="#home" className="App-header-logo">
          <img src={logo} alt="Logo" className="Site-logo"></img>
          <h1 className="Main-header">
            {t("linka")} <b>{t("LIA")}</b>
          </h1>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Container className="navbar-container">
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="#We-offer" className="navbar-item">
                {t("we_offer_nav")}
              </Nav.Link>
              <Nav.Link href="#When-you-call" className="navbar-item">
                {t("when_you_call_nav")}
              </Nav.Link>
              <Nav.Link href="#Opening-hours" className="navbar-item">
                {t("opening_hours_nav")}
              </Nav.Link>
              <Nav.Link href="#Operator" className="navbar-item">
                {t("operator_nav")}
              </Nav.Link>
              <Nav.Link href="#Stories" className="navbar-item">
                {t("stories_nav")}
              </Nav.Link>
              <Nav.Link href="#Another-help-options" className="navbar-item">
                {t("another_help_options_nav")}
              </Nav.Link>
            </Nav>
            <Nav className="ml-auto">
              <LanguageSelector></LanguageSelector>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  }
}

export default withTranslation()(AppHeader);
