import AppHeader from "../components/HomePage/appHeader";
import logo from "../logo.svg";
import AppContent from "../components/HomePage/appContent";
import AppFooter from "../components/appFooter";
import React from "react";

const HomePage = () => {
    return (<>
        <AppHeader imgSrc={logo} imgAlt="Page logo"></AppHeader>
        <AppContent></AppContent>
        <AppFooter></AppFooter></>);
}

export default HomePage;