import React from "react";
import { withTranslation } from "react-i18next";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import logoWhite from "../logo-white.svg";

class AppFooter extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <footer className="App-footer">
        <Container>
          <Row xs={1} sm={1} md={3}>
            <Col>
              <div className="App-footer-logo">
                <img src={logoWhite} alt="Logo" className="Site-logo"></img>
                <h2 className="Main-header">
                  {t("linka")} <b>{t("LIA")}</b>
                </h2>
              </div>
              <ul>
                <li>
                  <a href={"tel:" + t("phonenumber1")}>{t("phonenumber1")}</a>
                </li>
                <li>
                  {t("operator_email_text")}{" "}
                  <b>
                    <a href={"mailto:" + t("operator_email")}>
                      {t("operator_email")}
                    </a>
                  </b>
                </li>
              </ul>
            </Col>
            <Col>
              <h2>{t("footer_sections")}</h2>
              <a href="#We-offer">{t("we_offer_nav")}</a>
              <br></br>
              <a href="#When-you-call">{t("when_you_call_nav")}</a>
              <br></br>
              <a href="#Opening-hours">{t("opening_hours_nav")}</a>
              <br></br>
              <a href="#Operator">{t("operator_nav")}</a>
              <br></br>
              <a href="#Stories">{t("stories_nav")}</a>
              <br></br>
              <a href="#Another-help-options">
                {t("another_help_options_nav")}
              </a>
              <br></br>
            </Col>
            <Col>
              <h2>{t("operator_footer")}</h2>
              <p>
                <a href="https://www.mpsv.cz/">{t("footer_ministry")}</a>
                <a href="https://www.capld.cz/">{t("footer_capld")}</a>
              </p>
              <p>
                <a href="https://www.modralinka.cz/">
                  {t("footer_modra_linka")}
                </a>
              </p>
              <p>
                <a href="https://www.cervenykriz.eu/">{t("footer_cck")}</a>
              </p>
              <p>
                <a href="https://www.matejpokorny.cz/">
                  {t("footer_webmaster")}
                </a>
              </p>
            </Col>
          </Row>
        </Container>
      </footer>
    );
  }
}

export default withTranslation()(AppFooter);
