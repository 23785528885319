import React from "react";
import { withTranslation } from "react-i18next";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

class ContentSectionWeOffer extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <div className={"Section Section-type-1"}>
        <a class="anchor" id="We-offer"></a>
        <br></br>

        <Container fluid="lg">
          <Row>
            <Col>
              <p>
                {t("we_offer_text_4_a")}
                <b>{t("we_offer_text_4_b")}</b>
                {t("we_offer_text_4_c")}
              </p>
              <div className="story-block story-l-top">
                <p>{t("we_offer_text_3_a")}</p>
                <p>{t("we_offer_text_3_b")}</p>
                <p>{t("we_offer_text_3_c")}</p>
                <p>{t("we_offer_text_3_d")}</p>
                <p>{t("we_offer_text_3_e")}</p>
                <p>{t("we_offer_text_3_f")}</p>
                <p>{t("we_offer_text_3_g")}</p>
              </div>
              <p>
                <b>{t("we_offer_text_6_a")}</b>
                {t("we_offer_text_6_b")}
              </p>
            </Col>
            <Col>
              <p>
                {t("we_offer_text_2_a")}
                <b>{t("we_offer_text_2_b")}</b>
                {t("we_offer_text_2_c")}
              </p>
              <div className="story-block story-l-top">
                <p>{t("we_offer_text_1_a")}</p>
                <p>{t("we_offer_text_1_b")}</p>
                <p>{t("we_offer_text_1_c")}</p>
                <p>{t("we_offer_text_1_d")}</p>
                <p>{t("we_offer_text_1_e")}</p>
                <p>{t("we_offer_text_1_f")}</p>
                <p>{t("we_offer_text_1_g")}</p>
                <p>{t("we_offer_text_1_h")}</p>
                <p>{t("we_offer_text_1_i")}</p>
                <p>{t("we_offer_text_1_j")}</p>
                <p>{t("we_offer_text_1_k")}</p>
              </div>

              <p>{t("we_offer_text_5d")}</p>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default withTranslation()(ContentSectionWeOffer);
