import React from 'react';
import { withTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class contentSectionStories extends React.Component {

  render() {
    const { t } = this.props;
    return (
      <div className="Section Section-type-1">
        <Container>
          <a class="anchor" id="Stories"></a>
          <h1>{t("stories")}</h1>
          <p>{t("stories_text_1")}</p>
          <Container fluid="lg">
            <Row>
              <Col className="align-self-center">
                <p className="story-block story-l">{t("stories_text_2")}</p>
              </Col>
              <Col className="align-self-center">
                <p className="story-block story-r">{t("stories_text_3")}</p>
              </Col>
            </Row>
            <Row>
              <Col className="align-self-center">
                <p className="story-block story-l">{t("stories_text_4")}</p>
              </Col>
              <Col className="align-self-center">
                <p className="story-block story-r">{t("stories_text_5")}</p>
              </Col>
            </Row>
            <Row>
              <Col className="align-self-center">
                <p className="story-block story-l">{t("stories_text_6")}</p>
              </Col>
              <Col className="align-self-center">
                <p className="story-block story-r">{t("stories_text_7")}</p>
              </Col>
            </Row>
          </Container>
          <p className="story-block story-l">{t("stories_text_8")}</p>
        </Container>
      </div>
    );
  }
}

export default withTranslation()(contentSectionStories);